import React, { useState } from "react";
import GalleryImage from "../components/GalleryImage";
import { useQuery } from "@tanstack/react-query";
import { getGalleryImages } from "../services/galleryService";
import { Spinner } from "@nextui-org/react";

export const Gallery = () => {
  const [tab, setTab] = useState("Interior");

  const { data: gallery, isLoading } = useQuery({
    queryKey: "gallery",
    queryFn: getGalleryImages,
  });

  return (
    <div className="px-[6%] pt-[4%] md:px-[8%] mt-5 mb-20 md:mb-32">
      <h2 className="font-header text-3xl mb-3">Check Out Our Gallery</h2>
      <p className="leading-relaxed text-lg opacity-90">
        Lorem ipsum dolor sit amet consectetur <br /> adipisicing elit. Maxime
        mollitia, molestiae quas <br /> vel sint commodi repudiandae
        consequuntur <br /> voluptatum laborum
      </p>

      {isLoading ? (
        <div className="flex justify-center items-center h-60">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex space-x-7 mt-10 mb-12">
            {Object.keys(gallery).map((key) => (
              <div
                className={`flex items-center justify-center h-[3.5rem] md:w-[8.7rem] w-full border-primary cursor-pointer ${
                  key === tab && "border-b-2 font-medium"
                }`}
                onClick={() => setTab(key)}
              >
                {key}
              </div>
            ))}
          </div>
          <div className="mt-8 max-md:space-y-5 md:grid grid-cols-2 gap-9">
            {gallery[tab].map((image) => (
              <GalleryImage key={image.url} src={image.url} name={image.name} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
