import React from "react";
import { Header } from "./components/Header";
import { Outlet } from "react-router-dom";
import { Footer } from "./components/Footer";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { NextUIProvider } from "@nextui-org/react";
import { Toaster } from "react-hot-toast";

// axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.baseURL = "https://sama-backend.onrender.com";

const queryClient = new QueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <NextUIProvider>
        <Header />
        <Outlet />
        <Footer />
        <Toaster position="bottom-center" />
      </NextUIProvider>
    </QueryClientProvider>
  );
};
